<div class="header-container">
  <h3 class="header-title">Tenant {{customer.company_name}}</h3>
</div>
<div class="content">
  <nz-tabset nzType="card">
    <nz-tab [nzTitle]="infoTemplate">
      <ng-template #infoTemplate>
        <span nz-icon nzType="info-circle" nzTheme="outline"></span>
        Info
      </ng-template>
      <nz-skeleton [nzActive]="true" *ngIf="infoLoading"></nz-skeleton>
      <div *ngIf="!infoLoading" class="customer-data">
        <ng-container [ngTemplateOutlet]="tplContent"></ng-container>
      </div>
    </nz-tab>
    <nz-tab [nzTitle]="importerAccountTemplate" [nzDisabled]="infoLoading || !customer.pk">
      <ng-template #importerAccountTemplate>
        <span nz-icon nzType="audit" nzTheme="outline"></span>
        Importer
      </ng-template>
      <ng-template nz-tab>
        <importer-accounts [customer]="customer"></importer-accounts>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="featuresTemplate" [nzDisabled]="infoLoading || !customer.pk">
      <ng-template #featuresTemplate>
        <span nz-icon nzType="appstore-add" nzTheme="outline"></span>
        Features
      </ng-template>
      <ng-template nz-tab>
        <customer-features [customer]="customer"></customer-features>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="filterTemplate" [nzDisabled]="infoLoading || !customer.pk">
      <ng-template #filterTemplate>
        <span nz-icon nzType="filter" nzTheme="outline"></span>
        Filter
      </ng-template>
      <ng-template nz-tab>
        <tt-filter [customer]="customer"></tt-filter>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="usersTemplate" [nzDisabled]="infoLoading || !customer.pk">
      <ng-template #usersTemplate>
        <span nz-icon nzType="team" nzTheme="outline"></span>
        Users
      </ng-template>
      <ng-template nz-tab>
        <customer-users [customer]="customer" ></customer-users>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="columnsTemplate" [nzDisabled]="infoLoading || !customer.pk">
      <ng-template #columnsTemplate>
        <span nz-icon nzType="database" nzTheme="outline"></span>
        Data attributes
      </ng-template>
      <ng-template nz-tab>
        <customer-data-attributes [customer]="customer" ></customer-data-attributes>
      </ng-template>
    </nz-tab>
  </nz-tabset>
</div>
<ng-template #tplContent>
  <form nz-form class="customer-form" #customerForm="ngForm">
    <div class="customer-info">
      <div>
        <nz-divider nzText="Company" [nzOrientation]="'left'"></nz-divider>
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" nzLabelAlign="left" nzRequired>Company name</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Company name is required">
            <input nz-input [(ngModel)]="customer.company_name" name="company_name" required="true"
                   placeholder="Mustermann GmbH"/>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzLabelAlign="left" [nzSm]="24" [nzXs]="24" nzRequired>Admin Name</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Admin name is required">
            <input nz-input [(ngModel)]="customer.admin_name" name="admin_name" required="true"
                   placeholder="Max Mustermann"/>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzLabelAlign="left" [nzSm]="24" [nzXs]="24" nzRequired>Admin Email</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Admin Email is required">
            <input nz-input [(ngModel)]="customer.admin_email" name="admin_email" required="true"
                   placeholder="m.mustermann@muster.de"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div>
        <nz-divider nzText="Billing" [nzOrientation]="'left'"></nz-divider>
        <div nz-row [nzGutter]="[16,0]">
          <div nz-col [nzSpan]="16">
            <nz-form-label nzRequired>Street</nz-form-label>
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <input nz-input [(ngModel)]="customer.billing_address_street" name="billing_address_street"
                       placeholder="Musterstraße" required="true"/>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="8">
            <nz-form-label nzRequired>Nr.</nz-form-label>
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <input nz-input [(ngModel)]="customer.billing_address_street_no" name="billing_address_street_no"
                       placeholder="132" required="true"/>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row [nzGutter]="[16,0]">
          <div nz-col [nzSpan]="8">
            <nz-form-label nzRequired>Zip code</nz-form-label>
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <input nz-input [(ngModel)]="customer.billing_address_zip_code" name="billing_address_zip_code"
                       placeholder="20253" required="true"/>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="8">
            <nz-form-label nzRequired>City</nz-form-label>
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <input nz-input [(ngModel)]="customer.billing_address_city" name="billing_address_city"
                       placeholder="Hamburg" required="true"/>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="8">
            <nz-form-label nzRequired>Country</nz-form-label>
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-select nzPlaceHolder="Country" [(ngModel)]="customer.billing_address_country" name="country" required="true"
                           style="width: 100%;">
                  <nz-option nzLabel="Germany" nzValue="Germany"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <nz-form-item>
          <nz-form-label nzLabelAlign="left" [nzSm]="24" [nzXs]="24" nzRequired>Contact Phone</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Contact Phone is required">
            <input nz-input [(ngModel)]="customer.billing_address_phone" name="billing_address_phone" required="true"
                   placeholder="+1234567890"/>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div class="customer-action">
      <button [disabled]="!(customerForm.valid && customerForm.dirty)"
              (click)="saveChanges(customerForm)" [nzLoading]="saving"
              nz-button nzSize="large" nzType="primary" nzDanger>
        <span nz-icon nzType="save" nzTheme="outline"></span>
        {{!customer.pk ? "Create Tenant" : "Save changes"}}
      </button>
    </div>
  </form>
</ng-template>
<ng-template>

</ng-template>
