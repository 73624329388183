import { CommonModule } from '@angular/common';
import { Component, OnChanges, OnInit, SimpleChanges, TemplateRef, inject } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import {Subscription, map, switchMap, take, EMPTY} from 'rxjs';
import { CustomersService } from '../../../services/customers/customers.service';
import { Customer } from '../../../model/models';
import { ImporterAccountComponent } from '../../importer-accounts/importer-accounts.component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { CustomerFeaturesComponent } from '../customer-features/customer-features.component';
import { NzNotificationModule, NzNotificationService } from 'ng-zorro-antd/notification';
import { Buffer } from 'buffer';
import { Router } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { FilterComponent } from '../../filter/filter.component';
import {CustomerUsersComponent} from "../customer-users/customer-users.component";
import {CustomerDataAttributesComponent} from "./customer-data-attributes/customer-data-attributes.component";

@Component({
  selector: 'customer',
  standalone: true,
  imports: [CommonModule, FormsModule, NzButtonModule, NzFormModule, NzIconModule, NzInputModule, NzSelectModule, NzTabsModule, ImporterAccountComponent, NzSkeletonModule, NzDividerModule, CustomerFeaturesComponent, NzNotificationModule, FilterComponent, CustomerUsersComponent, CustomerDataAttributesComponent],
  templateUrl: './customer.component.html',
  styleUrl: './customer.component.scss'
})
export class CustomerComponent implements OnInit {
  private subscription = new Subscription()
  route = inject(ActivatedRoute)
  customersService = inject(CustomersService);
  notification = inject(NzNotificationService)
  customer: Customer = {} as Customer
  customersFeatures: any[] = new Array()
  infoLoading = true
  saving = false
  isCreatingCustomer = false
  router = inject(Router)
  modal = inject(NzModalService);

  ngOnInit(): void {
    this.subscription.add(
      this.route.params.pipe(
        map((params: Params) => { return params['id'] }),
        switchMap( id => {
          if (id == 'create') {
            this.infoLoading = false;
            this.isCreatingCustomer = true;
            return EMPTY
          }
         return this.customersService.getCustomersById(id)
        })
      ).subscribe((res: any) => { this.customer = res ; this.infoLoading = false})
    )
  }

  saveChanges(customerForm: NgForm) {
    this.saving = true
    if(this.isCreatingCustomer){
      this.customersService.createNewCustomer(this.customer).pipe(take(1)).subscribe((res)=>{
        this.saving = false
        this.customer = res
        this.notification.success(
          "",
          `New tenant created successfully`
        )
      })
    }else {
      this.customersService.editCustomer(this.customer).pipe(
        switchMap(res => this.customersService.getCustomersById(Buffer.from(res.pk).toString('base64')))
      ).subscribe((res:any) => {
        // this.customer = res
        customerForm.resetForm(res)
        this.saving = false
        this.notification.success(
          "",
          `Changes to tenant ${this.customer.company_name} saved`
        )
      })
    }
  }

  createFilterModal(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>) {
    let nzTitle = 'Filter hinzufügen'
    let data = {}
    const modal: NzModalRef = this.modal.create({
      nzTitle: nzTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzData: data
    });
  }
}
