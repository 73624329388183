import {Component, inject, OnInit, TemplateRef} from '@angular/core';
import {MappingService} from "../../services/mapping/mapping.service";
import {CommonModule} from "@angular/common";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzTableModule} from "ng-zorro-antd/table";
import {FormsModule} from "@angular/forms";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzModalModule, NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {FeatureComponent} from "../features/feature/feature.component";
import {NzSpinModule} from "ng-zorro-antd/spin";
import {NzSelectModule} from "ng-zorro-antd/select";
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzInputModule } from 'ng-zorro-antd/input';
import { Mapping } from '../../model/models';

@Component({
  selector: 'mapping',
  standalone: true,
  imports: [CommonModule, FormsModule, NzIconModule, NzTableModule, NzButtonModule, NzFormModule, FormsModule, NzModalModule, FeatureComponent, NzSpinModule, NzSelectModule, NzTypographyModule, NzDropDownModule, NzInputModule],
  templateUrl: './mapping.component.html',
  styleUrl: './mapping.component.scss'
})
export class MappingComponent implements OnInit {
  mappingService = inject(MappingService);
  modal = inject(NzModalService);
  supplierSystems: any[] = [];

  loading = true;
  checked = false;
  listOfData = new Array<Mapping>();
  searchInput: string | null = null
  setOfCheckedId = new Set<string>();
  deleteTextValue = ""

  ngOnInit(): void {
    this.getVehicleOrderTypeMappings();
    this.getSupplierSystems();
  }

  openMappingModal(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, item?: Mapping): void {
    let nzTitle = ''
    let data = {} as Mapping
    if(item){
      data = JSON.parse(JSON.stringify(item))
      nzTitle = 'Edit Mapping'
    } else{
      nzTitle = 'Add new Mapping'
    }
    const modal: NzModalRef = this.modal.create({
      nzTitle: nzTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzData: data
    });
  }

  getSupplierSystems() {
    this.mappingService.getSupplierSystems().subscribe(res => {
      this.supplierSystems = res
    })
  }

  getVehicleOrderTypeMappings() {
    this.mappingService.getVehicleOrderTypeMappings().subscribe(res => {
      this.listOfData = res
      this.loading = false
    })
  }

  saveMapping(ref: any) {
    this.loading = true
    let mapping = ref.getConfig().nzData
    this.mappingService.putMapping(mapping).subscribe(res => {
      this.getVehicleOrderTypeMappings();
      ref.close();
    })
  }

  deleteMapping(ref: NzModalRef){
    this.loading = true;
    let mappingId = ref.getConfig().nzData.pk
    this.mappingService.deleteMapping(mappingId).subscribe(()=>{
      this.getVehicleOrderTypeMappings();
      ref.close()
    })
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfData.every((elem: Mapping) => this.setOfCheckedId.has(elem.pk!));
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.listOfData
      .forEach((elem: Mapping) => this.updateCheckedSet(elem.pk!, checked));
    this.refreshCheckedStatus();
  }

  deleteMappingModal(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, item: any): void {
    const modal: NzModalRef = this.modal.create({
      nzTitle: 'Delete mapping',
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzData: item,
    });
    modal.afterClose.subscribe(() => this.deleteTextValue = '');
  }

  search(event: any) {
    // TODO
  }

}
