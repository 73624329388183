<div class="credentials-wrapper">
  <div class="container">
    <div class="acc">
        <div class="divider">
          <span nz-typography><strong>Importer accounts</strong></span>
          <button nz-button nzType="primary" nzDanger (click)="addImporter(tplContent, tplFooter)">
            <span nz-icon nzType="plus"></span>
            Add a importer
          </button>
        </div>
      <nz-skeleton [nzActive]="true" *ngIf="accLoading"></nz-skeleton>
      <div class="accounts" *ngIf="!accLoading">
        <nz-empty *ngIf="accounts.length == 0" nzNotFoundImage="simple"></nz-empty>
        <div *ngFor="let importerAccount of accounts">
          <nz-card [nzTitle]="importerAccount.supplier_system_partner_name" [nzExtra]="verificationTemplate">
            <div class="switch">
              <span>Enabled:</span>
              <nz-switch [nzDisabled]="importerAccount.enabled"></nz-switch>
            </div>
            <nz-divider></nz-divider>
            <div>
              <h3>Supplier System ID</h3>
              {{importerAccount.supplier_system_partner_id}}
            </div>
            <div>
              <h3>ID</h3>
              {{importerAccount.id}}
            </div>
            <nz-divider></nz-divider>
            <div>
              <button nz-button nzType="default" (click)="deleteAccount(tplDeleteAccount, tplDeleteAccountFooter, importerAccount)">
                <span nz-icon nzType="delete" nzTheme="outline"></span>
                  Delete importer account
              </button>
            </div>
          </nz-card>
          <ng-template #verificationTemplate>
            <ng-container [ngSwitch]="(importerAccount.id| verificationStatus : customer.pk | async)">
              <span *ngSwitchCase="'unverified'" nz-tooltip [nzTooltipTitle]="'Verification in progress'" nz-typography nzType="secondary">
                <span nz-icon nzType="info-circle" nzTheme="outline"></span>
              </span>
              <span *ngSwitchCase="'failed'" nz-tooltip [nzTooltipTitle]="'Verification failed'" nz-typography nzType="danger">
                <span nz-icon nzType="warning" nzTheme="outline"></span>
              </span>
              <span *ngSwitchCase="'verified'" nz-tooltip [nzTooltipTitle]="'Verification successful'" nz-typography nzType="success">
                <span nz-icon nzType="check-circle" nzTheme="outline"></span>
              </span>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #tplContent let-ref="modalRef">
  <form nz-form #credentialForm="ngForm" nzLabelAlign="left">
    <nz-form-item>
      <nz-form-label nzRequired [nzSm]="24" [nzXs]="24">Customer supplier system</nz-form-label>
      <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback
        nzErrorTip="Supplier system is required" nzValidateStatus="{{supplierSystem == null ? 'error' : 'succes'}}">
        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a supplier system" 
          [(ngModel)]="supplierSystem" name="supplierSystemAuthId" (ngModelChange)="selectSupplierSystem($event)">
          <nz-option *ngFor="let supplierSystem of availableSystemInfoList" 
            [nzLabel]="supplierSystem.name" 
            [nzValue]="supplierSystem">
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <div *ngIf="supplierSystem">
      <ng-container *ngIf="existingCredential">
        <span nz-icon nzType="info-circle" nzTheme="outline"></span>
        <span>
          Existing credentials will be used to create importer configuration
        </span>
      </ng-container>
      <ng-container *ngIf="existingCredential == undefined">
        <nz-form-item>
          <nz-form-label  [nzSm]="24" [nzXs]="24" nzRequired>Username</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback>
            <input [(ngModel)]="importerAccount?.secrets!.username"
              nz-input name="username" placeholder="Username for supplier system" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired>Password</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback>
            <input [(ngModel)]="importerAccount?.secrets!.password"
              nz-input name="password" placeholder="Password" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="supplierSystem.supplier_system_auth_id == 'grp'">
          <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired>OTP secret</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback>
            <input [(ngModel)]="importerAccount?.secrets!.otp_secret_key"
              nz-input name="otp" placeholder="OTP secret" />
          </nz-form-control>
        </nz-form-item>
      </ng-container>
    </div>
  </form>
  <div *ngIf="error" nz-typography nzType="danger">
    {{error.error_reason}}
  </div>
</ng-template>
<ng-template #tplFooter let-ref="modalRef">
  <button nz-button (click)="closeModal(ref)">Cancel</button>
  <button nz-button (click)="newCredentials(ref)" nzDanger nzType="primary" [nzLoading]="accountCRUD"
    [disabled]="isCreateDisabled()">
    <!-- [disabled]="!(importerAccount?.supplier_system_auth_id && importerAccount?.secrets?.username && importerAccount?.secrets?.password && importerAccount?.secrets?.otp_secret_key)"> -->
    <span nz-icon nzType="plus"></span>
    Add a importer
  </button>
</ng-template>

<ng-template #tplDeleteAccount let-ref="modalRef">
  <p>
    This will <b>permanently remove</b> the importer account from the system. This cannot be undone.
  </p>
  <p>Enter “Delete” and the account name into the field below to proceed:</p>
  <form nz-form>
    <nz-form-item>
      <nz-form-control nzHasFeedback nzErrorTip="Feature name is required">
        <input class="form-item-bordered" nz-input [(ngModel)]="deleteTextValue" name="feature_delete_text"
               required="true" [placeholder]="'Delete ' + ref.getConfig().nzData.supplier_system_partner_id"/>
      </nz-form-control>
    </nz-form-item>
  </form>
  <div *ngIf="error" nz-typography nzType="danger">
    {{error.error_reason}}
  </div>
</ng-template>
<ng-template #tplDeleteAccountFooter let-ref="modalRef">
  <button nz-button (click)="ref.close()">Cancel</button>
  <button nz-button (click)="delete(ref)" nzDanger nzType="primary" [nzLoading]="accountCRUD"
    [disabled]="deleteTextValue != ('Delete ' + ref.getConfig().nzData.supplier_system_partner_id)"
    >Delete</button>
</ng-template>
