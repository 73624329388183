import { Component, inject, OnInit } from '@angular/core';
import { VariablesService } from "../../services/variables/variables.service";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { getDataAttributesColumns } from "../customers/customer/customer-data-attributes/data-attributes-table-columns";
import { CommonModule } from "@angular/common";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { FormsModule } from "@angular/forms";

@Component({
  selector: 'variable-standard-names',
  standalone: true,
  imports: [CommonModule, NzSpinModule, NzTableModule, NzInputModule, FormsModule, NzCollapseModule],
  templateUrl: './variable-standard-names.component.html',
  styleUrl: './variable-standard-names.component.scss'
})
export class VariableStandardNamesComponent implements OnInit {
  standardVariableNamesService = inject(VariablesService);
  translations: any;
  groupedData: any;
  isLoading = false;
  private inputTimeout: any;

  ngOnInit() {
    this.isLoading = true;
    const groupedColumns = getDataAttributesColumns();
    this.standardVariableNamesService.getStandardVariableNames('de').subscribe(res => {
      this.translations = res;

      this.groupedData = groupedColumns.map(group => ({
        ...group,
        children: group.children.map(child => ({
          ...child,
          headerName: this.translations.ExpertView.Table.Columns[child.field]?.name || child.headerName
        }))
      }));

      this.isLoading = false;
    });
  }

  onInputChange(field: string, value: string) {
    clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      if (!this.translations.ExpertView.Table.Columns[field]) {
        this.translations.ExpertView.Table.Columns[field] = { name: value };
      } else {
        this.translations.ExpertView.Table.Columns[field].name = value;
      }

      this.groupedData.forEach((group: { children: any[]; }) => {
        group.children.forEach(child => {
          if (child.field === field) {
            child.headerName = value;
          }
        });
      });

      this.save();
    }, 500);
  }

  save() {
    this.standardVariableNamesService.setStandardVariableNames(this.translations, 'de').subscribe();
  }
}
