import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzDividerModule } from 'ng-zorro-antd/divider';

@Component({
  selector: 'login',
  standalone: true,
  imports: [NzFormModule, NzIconModule, NzInputModule, NzButtonModule, ReactiveFormsModule, FormsModule, NzSpaceModule, NzDividerModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  fb = inject(NonNullableFormBuilder)
  // userService = inject(UserLoginService)
  errorMessage: string = "";
  router = inject(Router);
  // trackingService = inject(TrackingService)
  validateForm: FormGroup<{
    userName: FormControl<string>;
    password: FormControl<string>;
    remember: FormControl<boolean>;
  }> = this.fb.group({
    userName: ['', [Validators.required]],
    password: ['', [Validators.required]],
    remember: [true]
  });

  ngOnInit() {
    // this.userService.isAuthenticated(this);
  }

  submitForm(): void {
    if (this.validateForm.valid) {
      let user = {
        username: this.validateForm.value.userName ?? "" ,
        password: this.validateForm.value.password ?? ""
      }
      // this.userService.authenticate(user.username, user.password, this);
      // this.trackingService.trackEvent(ACTIONS.login_submitted, {})
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    // this.trackingService.trackEvent(ACTIONS.login_submitted, {})
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
        this.router.navigate(['/']);
    }
  }
}
