[{"group_id":"de000154","group_name":"Lebsack-Boyle"},
{"group_id":"de000004","group_name":"Morar, Gutmann and Bogan"},
{"group_id":"de000377","group_name":"Abbott-Roob"},
{"group_id":"de000425","group_name":"Larkin-Leffler"},
{"group_id":"de000064","group_name":"Grady-Auer"},
{"group_id":"de000343","group_name":"Carter, D'Amore and Russel"},
{"group_id":"de000998","group_name":"Mueller-Cummings"},
{"group_id":"de000182","group_name":"Monahan-Lehner"},
{"group_id":"de000483","group_name":"McKenzie-Homenick"},
{"group_id":"de000935","group_name":"Schinner and Sons"},
{"group_id":"de000080","group_name":"Okuneva, Lowe and Goldner"},
{"group_id":"de000827","group_name":"Schmeler, Skiles and Okuneva"},
{"group_id":"de000554","group_name":"Johns LLC"},
{"group_id":"de000047","group_name":"Hermiston, Cronin and Bergnaum"},
{"group_id":"de000853","group_name":"Lubowitz-Goyette"},
{"group_id":"de000546","group_name":"Schimmel and Sons"},
{"group_id":"de000476","group_name":"Hintz-Jerde"},
{"group_id":"de000359","group_name":"Gleichner, Ullrich and Hessel"},
{"group_id":"de000939","group_name":"Feest Group"},
{"group_id":"de000844","group_name":"Funk-Batz"},
{"group_id":"de000419","group_name":"Bergnaum-Yundt"},
{"group_id":"de000924","group_name":"Doyle, Frami and Abernathy"},
{"group_id":"de000102","group_name":"Keeling Group"},
{"group_id":"de000452","group_name":"Baumbach Group"},
{"group_id":"de000579","group_name":"Lowe, Medhurst and Dickens"},
{"group_id":"de000576","group_name":"Abbott-Casper"},
{"group_id":"de000029","group_name":"Volkman-Batz"},
{"group_id":"de000512","group_name":"Bode, D'Amore and McDermott"},
{"group_id":"de000908","group_name":"Conroy, Abernathy and Gutkowski"},
{"group_id":"de000208","group_name":"Miller, Daniel and Grant"},
{"group_id":"de000428","group_name":"Reichel-Mante"},
{"group_id":"de000804","group_name":"Stoltenberg Inc"},
{"group_id":"de000334","group_name":"Zemlak-Gerlach"},
{"group_id":"de000433","group_name":"Shields and Sons"},
{"group_id":"de000960","group_name":"O'Connell-Wintheiser"},
{"group_id":"de000717","group_name":"Bergstrom-Stoltenberg"},
{"group_id":"de000811","group_name":"McKenzie LLC"},
{"group_id":"de000999","group_name":"Sipes-Gottlieb"},
{"group_id":"de000300","group_name":"Hauck, Berge and Stiedemann"},
{"group_id":"de000135","group_name":"Hane Inc"},
{"group_id":"de000076","group_name":"Nicolas-Schamberger"},
{"group_id":"de000541","group_name":"Bechtelar Group"},
{"group_id":"de000281","group_name":"Stiedemann, Ryan and Nikolaus"},
{"group_id":"de000811","group_name":"Wisozk, Cormier and Schinner"},
{"group_id":"de000574","group_name":"O'Kon and Sons"},
{"group_id":"de000745","group_name":"Wolff-Halvorson"},
{"group_id":"de000527","group_name":"Grady LLC"},
{"group_id":"de000831","group_name":"Ratke-Walsh"},
{"group_id":"de000365","group_name":"Kiehn-Lebsack"},
{"group_id":"de000662","group_name":"Schmidt, Quigley and Ledner"},
{"group_id":"de000244","group_name":"Borer, Murazik and Smith"},
{"group_id":"de000299","group_name":"Balistreri Inc"},
{"group_id":"de000815","group_name":"Larkin and Sons"},
{"group_id":"de000749","group_name":"Conroy, Frami and Denesik"},
{"group_id":"de000024","group_name":"Zboncak Inc"},
{"group_id":"de000091","group_name":"Bode-Emard"},
{"group_id":"de000274","group_name":"Von, Schamberger and Boyer"},
{"group_id":"de000030","group_name":"Kuvalis, Murazik and Nicolas"},
{"group_id":"de000711","group_name":"Heidenreich-Wintheiser"},
{"group_id":"de000924","group_name":"Watsica, Howell and Simonis"},
{"group_id":"de000916","group_name":"Spinka, Padberg and Kulas"},
{"group_id":"de000287","group_name":"Spinka-Harber"},
{"group_id":"de000198","group_name":"Johns-Balistreri"},
{"group_id":"de000490","group_name":"Runolfsson-Boyle"},
{"group_id":"de000557","group_name":"Douglas-Koelpin"},
{"group_id":"de000689","group_name":"Mueller, Keeling and Maggio"},
{"group_id":"de000058","group_name":"Daugherty, Rempel and Langworth"},
{"group_id":"de000684","group_name":"Weissnat-Kuphal"},
{"group_id":"de000423","group_name":"Dooley-Heaney"},
{"group_id":"de000393","group_name":"Shields, Hagenes and Wisoky"},
{"group_id":"de000220","group_name":"Rath, Tillman and Bartoletti"},
{"group_id":"de000373","group_name":"Kiehn-Kuhn"},
{"group_id":"de000647","group_name":"Kassulke-Sawayn"},
{"group_id":"de000967","group_name":"Swift-Walter"},
{"group_id":"de000344","group_name":"Wolff Group"},
{"group_id":"de000817","group_name":"Luettgen-Blanda"},
{"group_id":"de000760","group_name":"Cremin, Walter and Simonis"},
{"group_id":"de000715","group_name":"Stehr Group"},
{"group_id":"de000388","group_name":"Ziemann Group"},
{"group_id":"de000247","group_name":"Jacobi-Bashirian"},
{"group_id":"de000213","group_name":"Koelpin, Steuber and Sauer"},
{"group_id":"de000103","group_name":"Smitham Inc"},
{"group_id":"de000625","group_name":"Effertz-Hirthe"},
{"group_id":"de000382","group_name":"Collins, Dibbert and Hoppe"},
{"group_id":"de000949","group_name":"Towne Inc"},
{"group_id":"de000877","group_name":"Olson, Brekke and Conroy"},
{"group_id":"de000916","group_name":"Parker, Turcotte and Brekke"},
{"group_id":"de000218","group_name":"Johns LLC"},
{"group_id":"de000043","group_name":"Turcotte LLC"},
{"group_id":"de000492","group_name":"Powlowski Inc"},
{"group_id":"de000154","group_name":"Gibson, Donnelly and Schamberger"},
{"group_id":"de000544","group_name":"Kuhn, Hoppe and Rice"},
{"group_id":"de000256","group_name":"Dicki LLC"},
{"group_id":"de000724","group_name":"Lemke, Gusikowski and Bogan"},
{"group_id":"de000722","group_name":"Langworth LLC"},
{"group_id":"de000751","group_name":"Mayert-Simonis"},
{"group_id":"de000330","group_name":"Reynolds LLC"},
{"group_id":"de000084","group_name":"Gerhold and Sons"},
{"group_id":"de000953","group_name":"Will, Blanda and Schoen"},
{"group_id":"de000433","group_name":"Wolff Group"}]