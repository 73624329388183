<div class="header-container">
  <h3 class="header-title">Tenants</h3>
  <div class="header-actions-container">
    <search-input
      [placeholder]="'Search tenant'"
      (searchValue)="handleSearchClick($event)"
    ></search-input>
    <button class="primary-button" nz-button nzSize="large" nzType="primary" nzDanger
            (click)="customerCreateLink()">
      <span nz-icon nzType="plus" nzTheme="outline"></span>
      Add new tenant
    </button>
  </div>
</div>
<div class="content">
  <div *ngIf="isLoading" class="loader-container">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </div>
  <nz-table
    #customersTable
    [nzData]="searchValue? filteredListOfCustomers: listOfCustomers"
    class="yoma-table"
    id="group-table"
    [nzFrontPagination]="false"
    [nzTotal]="10"
    [nzPaginationType]="'small'"
    [nzShowPagination]="true"
  >
    <thead>
    <tr>
      <th
        [nzChecked]="checked"
        [nzIndeterminate]="indeterminate"
        nzLabel="Select all"
        (nzCheckedChange)="onAllChecked($event)"
      ></th>
      <th>Tenant</th>
      <th>Billing address</th>
      <th>Contact</th>
      <th>Admin</th>
      <th style="max-width: 100px"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of customersTable.data" (click)="customerLink(data)" class="data-row">
      <td
        [nzChecked]="setOfCheckedId.has(data.pk)"
        [nzDisabled]="data.disabled"
        [nzLabel]="data.name"
        (nzCheckedChange)="onItemChecked(data.pk, $event)"
        (click)="$event.stopPropagation()"
      ></td>
      <td>
        <div class="item-container">
          <span nz-typography>{{ data.company_name }}</span>
        </div>
      </td>
      <td>
        <div class="item-container">
          <span nz-typography>{{ data.billing_address_street }} {{data.billing_address_street_no}}</span>
          <span nz-typography>{{ data.billing_address_zip_code }} {{data.billing_address_city}}</span>
        </div>
      </td>
      <td>
        <div class="item-container">
          <span nz-typography>{{ data.contact_name }}</span>
          <span nz-typography nzType="danger">{{ data.contact_email }}</span>
        </div>
      </td>
      <td>
        <div class="item-container">
          <span nz-typography>{{ data.admin_name }}</span>
          <span nz-typography nzType="danger">{{ data.admin_email }}</span>
        </div>
      </td>
      <td (click)="$event.stopPropagation()">
        <div class="actions-container">
          <button nz-button nzType="default" nzShape="circle"
                  (click)="openCustomerDeleteModal(tplDeleteModal, tplDeleteModalFooter, data.pk)">
            <span nz-icon nzType="delete" nzTheme="outline"></span>
          </button>
        </div>
      </td>
    </tr>
    </tbody>
  </nz-table>

</div>

<ng-template #tplDeleteModal>
  <p>
    This will <b>permanently remove</b> the tenant <b>{{customer.company_name}}</b> and all of its associated
    data and orders from the system. This cannot be undone.

  </p>
  <p>Enter “Delete” and the tenants name into the field below to proceed:</p>
  <form nz-form>
    <nz-form-item>
      <nz-form-control nzHasFeedback nzErrorTip="Feature name is required">
        <input class="form-item-bordered" nz-input [(ngModel)]="deleteTextValue" name="feature_delete_text"
               required="true" [placeholder]="'Delete ' + customer.company_name"/>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>

<ng-template #tplDeleteModalFooter let-ref="modalRef">
  <button nz-button (click)="handleModalClose(ref)" class="form-button">Cancel</button>
  <button nz-button (click)="deleteCustomer(ref, customer.pk)" class="form-button" nzType="primary" nzDanger
          [disabled]="deleteTextValue.toLowerCase() != deleteText.toLowerCase()">Delete
  </button>
</ng-template>
