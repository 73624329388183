import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { GroupData } from '../../model/models';
import { MockDataService } from '../../mockdata/mock-data.service';
import { NzIconModule } from 'ng-zorro-antd/icon';


@Component({
  selector: 'groups',
  standalone: true,
  imports: [CommonModule, NzTableModule, NzButtonModule, NzAffixModule, NzIconModule],
  templateUrl: './groups.component.html',
  styleUrl: './groups.component.scss'
})
export class GroupsComponent implements OnInit {
  checked = false;
  loading = false;
  listOfData: GroupData[] = [];
  setOfCheckedId = new Set<string>();
  mockData = inject(MockDataService)

  ngOnInit(): void {
    this.loading = true
    setTimeout(() => {
      this.listOfData = this.mockData.getGroups()
      this.loading = false
    }, 800)
  }


  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfData.every(({ group_id }) => this.setOfCheckedId.has(group_id));
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.listOfData
      .forEach(({ group_id }) => this.updateCheckedSet(group_id, checked));
    this.refreshCheckedStatus();
  }

  sendRequest(): void {
    this.loading = true;
    const requestData = this.listOfData.filter(data => this.setOfCheckedId.has(data.group_id));
    console.log(requestData);
    setTimeout(() => {
      this.setOfCheckedId.clear();
      this.refreshCheckedStatus();
      this.loading = false;
    }, 1000);
  }
}
