import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import { Buffer } from 'buffer';
import {environment} from "../../../environments/environment";
import { Customer, CustomerFilter, ImporterAccount } from '../../model/models';


@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(private http: HttpClient) { }

  getCustomers(): Observable<any>{
    return this.http.get(`${environment.yomaApiURL}/customers`);
  }

  getCustomersById(customerId: string): Observable<any>{
    return this.http.get(`${environment.yomaApiURL}/customers/${customerId}`);
  }

  createNewCustomer(customer: any): Observable<any>{
    return this.http.put(`${environment.yomaApiURL}/customers`, customer);
  }

  deleteCustomer(customerId: string): Observable<any>{
    let encoded = Buffer.from(customerId).toString('base64')
    return this.http.delete(`${environment.yomaApiURL}/customers/${encoded}`);
  }

  editCustomer(customer: Customer): Observable<any>{
    let id = Buffer.from(customer.pk).toString('base64')
    return this.http.put(`${environment.yomaApiURL}/customers/${id}`, customer);
  }

  getCustomersFeatures(customerId: string): Observable<any>{
    let encodedCustomerId = Buffer.from(customerId).toString('base64');
    return this.http.get(`${environment.yomaApiURL}/customers/${encodedCustomerId}/features`)
  }
  addFeatureToCustomer(customerId: string, featureId: string, feature: any): Observable<any>{
    let encodedCustomerId = Buffer.from(customerId).toString('base64');
    let encodedFeatureId = Buffer.from(featureId).toString('base64');
    if(feature){
      return this.http.put(`${environment.yomaApiURL}/customers/${encodedCustomerId}/features/${encodedFeatureId}`, {}, {params: feature})
    } else {
      return this.http.put(`${environment.yomaApiURL}/customers/${encodedCustomerId}/features/${encodedFeatureId}`, {}, )
    }
  }

  deleteCustomersFeature(customerId: string, featureId: string): Observable<any>{
    let encodedCustomerId = Buffer.from(customerId).toString('base64');
    let encodedFeatureId = Buffer.from(featureId).toString('base64');
    return this.http.delete(`${environment.yomaApiURL}/customers/${encodedCustomerId}/features/${encodedFeatureId}`)
  }

  verifyCredentials(customerId: string, importerAccountId: string): Observable<any> {
    importerAccountId = Buffer.from(importerAccountId).toString('base64')
    customerId = Buffer.from(importerAccountId).toString('base64')
    return this.http.post(`${environment.yomaApiURL}/customers/${customerId}/importers/${importerAccountId}/verify`, {})
  }

  credentialsVerificationStatus(customerId: string, importerAccountId: string): Observable<any> {
    return this.http.get(`${environment.yomaApiURL}/customers/${customerId}/importers/${importerAccountId}/verify`)
  }

  getSupplierSystems(customerId?: string): Observable<any> {
    if(customerId) {
      let id = Buffer.from(customerId).toString('base64')
      return this.http.get(`${environment.yomaApiURL}/customers/${id}/importers/supplierSystems`)
    }
    return this.http.get(`${environment.yomaApiURL}/supplierSystems`)
  }

  addImporterAccounts(customerId: string,importerAccount: ImporterAccount): Observable<any> {
    customerId = Buffer.from(customerId).toString('base64')
    return this.http.post(`${environment.yomaApiURL}/customers/${customerId}/importers`, importerAccount)
  }

  getImporterAccounts(id: string): Observable<any> {
    id = Buffer.from(id).toString('base64')
    return this.http.get(`${environment.yomaApiURL}/customers/${id}/importers`)
  }

  deleteAccount(customerId: string,importerAccountId: string): Observable<any> {
    customerId = Buffer.from(customerId).toString('base64')
    importerAccountId = Buffer.from(importerAccountId).toString('base64')
    return this.http.delete(`${environment.yomaApiURL}/customers/${customerId}/importers/${importerAccountId}`)
  }

  getFilters(customerId: string): Observable<any> {
    customerId = Buffer.from(customerId).toString('base64')
    return this.http.get<any>(`${environment.yomaApiURL}/customers/${customerId}/filter`)
  }

  setFilter(customerId: string, filter: any): Observable<any> {
    customerId = Buffer.from(customerId).toString('base64')
    return this.http.put(`${environment.yomaApiURL}/customers/${customerId}/filter`, filter)
  }

  deleteFilter(customerId: string): Observable<any> {
    customerId = Buffer.from(customerId).toString('base64')
    return this.http.delete(`${environment.yomaApiURL}/customers/${customerId}/filter`, {observe: 'response'})
  }

  getFilterPreview(customerId: string): Observable<any> {
    customerId = Buffer.from(customerId).toString('base64')
    return this.http.get(`${environment.yomaApiURL}/customers/${customerId}/filter/preview`)
  }

  getUsers(customerId: string): Observable<any>{
    customerId = Buffer.from(customerId).toString('base64')
    return this.http.get(`${environment.yomaApiURL}/customers/${customerId}/users`);
  }

  getDataAttributes(customerId: string): Observable<any>{
    customerId = Buffer.from(customerId).toString('base64')
    return this.http.get(`${environment.yomaApiURL}/customers/${customerId}/data_attributes`)
  }

  setDataAttributes(customerId: string, dataAttributes: any): Observable<any>{
    customerId = Buffer.from(customerId).toString('base64')
    return this.http.put(`${environment.yomaApiURL}/customers/${customerId}/data_attributes`, dataAttributes)
  }
}
