[
  {
    "name": "Subin",
    "description": "Plexiglass",
    "price": 92,
    "renewalTime": 4
  },
  {
    "name": "Andalax",
    "description": "Aluminum",
    "price": 89,
    "renewalTime": 4
  },
  {
    "name": "Bytecard",
    "description": "Granite",
    "price": 100,
    "renewalTime": 9
  },
  {
    "name": "Job",
    "description": "Stone",
    "price": 62,
    "renewalTime": 8
  },
  {
    "name": "Gembucket",
    "description": "Stone",
    "price": 20,
    "renewalTime": 4
  },
  {
    "name": "Tempsoft",
    "description": "Brass",
    "price": 98,
    "renewalTime": 2
  },
  {
    "name": "Solarbreeze",
    "description": "Aluminum",
    "price": 40,
    "renewalTime": 9
  },
  {
    "name": "Flexidy",
    "description": "Plastic",
    "price": 8,
    "renewalTime": 1
  },
  {
    "name": "Bamity",
    "description": "Plastic",
    "price": 88,
    "renewalTime": 2
  },
  {
    "name": "Lotlux",
    "description": "Brass",
    "price": 67,
    "renewalTime": 3
  }
]
