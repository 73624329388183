<h3>Data attributes</h3>
<div class="loader" *ngIf="isLoading">
  <nz-spin nzSimple [nzSize]="'large'" nzTip="Loading..."></nz-spin>
</div>
<nz-collapse *ngIf="!isLoading">
  @for (column of columnDefs; track $index) {
    <nz-collapse-panel [nzHeader]="getPanelHeader(column)" class="custom-collapse-panel">
      <div class="panel-content">
      <nz-table
        #basicTable
        [nzData]="column.children"
        [nzBordered]="true"
        [nzSize]="'middle'"
        [nzFrontPagination]="false"
      >
        <thead>
        <tr>
          <th>
            <label nz-checkbox [(ngModel)]="column.allSelected" (ngModelChange)="toggleSelectAll(column)"></label>
          </th>
          <th>Name</th>
          <th>Variable</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>
            <label nz-checkbox [(ngModel)]="data.selected" (ngModelChange)="onRowSelect(column)">
            </label>
          </td>
          <td>{{ data.headerName }}</td>
          <td>{{ data.field }}</td>
        </tr>
        </tbody>
      </nz-table>
      </div>
    </nz-collapse-panel>
  }
</nz-collapse>
