<p class="divider">
  <span class="text">Filter</span
  ><span class="line"></span>
</p>
<nz-skeleton [nzActive]="true" [nzLoading]="isCRUD" [nzParagraph]="{ rows: 2 }">
  <div class="filter filter-wrap">
    @for (filters of customerFilter.filter; track filters; let filterIdx = $index) {
      <div class="filter-lvl" [class]="filters.length > 1 ? 'filter-box' : ''">
        @for (orFilter of filters; track $index) {
          <div class="and">+</div>
          <div class="filter-box filter-wrap">
            @for (item of orFilter|keyvalue; track $index) {
              <div class="filter-name">{{mappings[item.key]}}: </div>
              @for (value of item.value; track $index) {
                <nz-tag class="filter-tag" [nzMode]="'closeable'" (nzOnClose)="deleteFilter($event, value, item.key, filterIdx)">{{value}}</nz-tag>
              }
            }
            @if(filters.length == 1) {
              <button nz-button nzType="primary" nzDanger (click)="createFilterModal(addFilter, addFilterFooter, filterIdx)">
                <span nz-icon nzType="plus" nzTheme="outline"></span>
              </button>
            }
          </div>
        }
        @if(filters.length != 1) {
          <button nz-button nzType="primary" nzDanger (click)="createFilterModal(addFilter, addFilterFooter, filterIdx)">
            <span nz-icon nzType="plus" nzTheme="outline"></span>
          </button>
        }
      </div>
      <div class="or">Oder</div>
    }
    <button class="or" nz-button nzType="primary" nzSize="large" nzDanger (click)="createFilterModal(addFilter, addFilterFooter)">
      <span nz-icon nzType="plus" nzTheme="outline"></span>
      Filter
    </button>
  </div>
</nz-skeleton>
<div class="preview-content">
  <nz-table
    [nzLoading]="vehiclesPreviewLoading"
    [nzData]="vehicleResults"
    [nzShowPagination]="false"
    [nzFrontPagination]="false"
    [nzSize]="'small'"
  >
    <thead>
    <tr>
      <th>Order</th>
      <th>Kunde</th>
      <th>Kunden ID</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of vehicleResults">
      <td>
        <span>
          <img src="https://cdn.imagin.studio/getImage?customer=yoma-de&make={{item.vehicle_brand}}&modelFamily={{item.vehicle_model}}&angle=28&zoomType=fullscreen&paintdescription={{text2Slug(item.vehicle_color)}}" width="80"/>
        </span>
        <span style="margin-left: 6px;">
          {{ item.vehicle_model }}
        </span>
      </td>
      <td>{{ item.cardealer_customer_name }}</td>
      <td>{{ item.cardealer_customer_id }}</td>
    </tr>
    </tbody>
  </nz-table>
</div>

<ng-template #addFilter let-ref="modalRef">
  <div *ngIf="selectedProp?.key == 'ordering_partner_group_id'">
    <p class="warning-text">⚠️ Jede “Bestellende Gruppen ID” hat seine eigenen Bemerkungsfelder, sobald diese ID ausgewählt wird, sieht dieser Tenant die Bemerkungsfelder der Bestellende Gruppen ID”</p>
    <p class="warning-text">⚠️ Sobald mehr als eine “Bestellende Gruppen ID” für ein und den selben Tenant verwendet wird, werden die Bemerkungsfelder beider IDs vermischt dargestellt</p>
  </div>
  <form nz-form>
    <nz-form-item>
      <nz-form-label nzRequired>Filter property</nz-form-label>
      <nz-form-control>
        <nz-select
          name="filterKey"
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="Select property"
          (ngModelChange)="selectFilterProp($event, ref)"
          [compareWith]="compareFn"
          [nzFilterOption]="nzFilterOption"
          [(ngModel)]="selectedProp"
        >
          @for (item of mappings | keyvalue: zero; track $index) {
          <nz-option nzCustomContent [nzLabel]="item.value" [nzValue]="item">
            <div class="mapping-option-item">
              <span nz-typography>{{item.value}}</span>
              <span nz-typography nzType="secondary">{{item.key}}</span>
            </div>
          </nz-option>
          }
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    @if (filter && selectedProp) {
    <nz-form-label>Filter data</nz-form-label>
    <div class="filter-box filter-wrap">
      <div class="filter-box-label" style="padding-right: 6px">
        {{ selectedProp.value }}:
      </div>
      @for (item of filter[selectedProp.key]; track $index) {
      <nz-tag
        class="filter-tag"
        [nzMode]="'closeable'"
        (nzOnClose)="deleteFilter($event, item)"
        >{{ item }}</nz-tag>
      }
    </div>
    <nz-form-item>
      <nz-form-label nzRequired>Filter value</nz-form-label>
      <nz-form-control>
        <nz-input-group nzSearch [nzAddOnAfter]="addInput">
          <input nz-input name="addFilterValue" #input />
        </nz-input-group>
        <ng-template #addInput>
          <button
            nz-button
            nzType="default"
            [disabled]="!input.value"
            (click)="addFilterValue(input)"
          >
            <span nz-icon nzType="plus"></span>
          </button>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    }
  </form>
</ng-template>
<ng-template #addFilterFooter let-ref="modalRef">
  <button nz-button (click)="closeModal(ref)">Cancel</button>
  <button
    nz-button
    [nzLoading]="isCRUD"
    [disabled]="isDisabled()"
    (click)="saveFilter(ref)"
    nzType="primary"
    nzDanger
  >
    Add filter
  </button>
</ng-template>
<ng-template #tplDeleteModal>
  <p>
    Wenn Sie eine Großkunde löschen, werden alle Einstellungen entfernt und der
    Benutzerzugriff wird gesperrt. Die monatliche Gebühr für diese Ansicht wird
    für den nächsten Abrechnungszyklus entfernt.
  </p>
  <p nz-typography nzType="danger">
    <strong>Dies kann nicht rückgängig gemacht werden.</strong>
  </p>
</ng-template>


