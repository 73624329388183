import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import { Buffer } from 'buffer';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {

  constructor(private http: HttpClient) { }

  getFeatures(): Observable<any>{
    return this.http.get(`${environment.yomaApiURL}/features`);
  }

  createNewFeature(feature: any): Observable<any>{
    return this.http.put(`${environment.yomaApiURL}/features`, feature);
  }

  deleteFeature(featureId: string): Observable<any>{
    let encoded = Buffer.from(featureId).toString('base64')
    return this.http.delete(`${environment.yomaApiURL}/features/${encoded}`);
  }

  editFeature(featureId: string, feature: any): Observable<any>{
    let encoded = Buffer.from(featureId).toString('base64')
    return this.http.put(`${environment.yomaApiURL}/features/${encoded}`, feature);
  }
}
