import { Component, Input, OnInit, Pipe, PipeTransform, TemplateRef, inject } from '@angular/core';
import { Credential, Customer, ImporterAccount } from '../../model/models';
import { take, Observable, Subject, takeUntil, EMPTY, delay, expand } from 'rxjs';
import { CustomersService } from '../../services/customers/customers.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { Buffer } from 'buffer';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Pipe({
  name: 'verificationStatus',
  standalone: true
})
export class VerificationStatusPipe implements PipeTransform {
  repeat$$ = new Subject<void>();
  customersService = inject(CustomersService);

  transform(accountId: string, customerId: string): Observable<any> {
    return new Observable((observer) => {
      let aid = Buffer.from(accountId).toString('base64');
      let cid = Buffer.from(customerId).toString('base64');
      if(!aid && !cid) { this.repeat$$.complete(); observer.complete() }
      this.customersService.credentialsVerificationStatus(cid, aid).pipe(
        expand((res:any) => {
          if(res == 'unverified') {
            return this.customersService.credentialsVerificationStatus(cid, aid).pipe(delay(60000))
          } 
            this.repeat$$.complete()
            return EMPTY
        }),
        takeUntil(this.repeat$$)
      ).subscribe(res => {
        observer.next(res)
      })
    })
  }
}

@Component({
  selector: 'importer-accounts',
  standalone: true,
  imports: [CommonModule, FormsModule, NzButtonModule, NzFormModule, NzModalModule, NzSelectModule, NzInputModule, NzDividerModule, NzIconModule, NzCardModule, NzSwitchModule, VerificationStatusPipe, NzSkeletonModule, NzEmptyModule, NzTypographyModule, NzToolTipModule],
  templateUrl: './importer-accounts.component.html',
  styleUrl: './importer-accounts.component.scss'
})

export class ImporterAccountComponent implements OnInit {
  @Input() customer!: Customer
  customersService = inject(CustomersService);
  modal = inject(NzModalService);
  existingCredential: ImporterAccount | undefined
  availableSystemInfoList = new Array()
  importerAccount: ImporterAccount | undefined
  accounts = new Array<ImporterAccount>()
  credentials = new Array<Credential>()
  supplierSystem: any | undefined
  accountCRUD = false
  accLoading = true
  error:any | undefined
  deleteTextValue = ""

  ngOnInit(): void {
    this.getAvailableSupplierSystem(this.customer.pk)
    this.getImporterAccounts(this.customer.pk)
  }

  selectSupplierSystem(event: any) {
    this.importerAccount = {} as ImporterAccount
    this.importerAccount.supplier_partner_segment = this.supplierSystem.supplier_partner_segment
    this.importerAccount.supplier_system_partner_id = this.supplierSystem.supplier_system_partner_id
    this.importerAccount.supplier_system_auth_id = this.supplierSystem.supplier_system_auth_id
    this.existingCredential = this.accounts
      .find(elem => 
        (elem.supplier_system_auth_id == event.supplier_system_auth_id) && (event.supplier_system_auth_id != "madax"))
    if(this.existingCredential && this.existingCredential.supplier_system_auth_id != "madax") {
      this.importerAccount.credentials_id = this.existingCredential.credentials_id
    } 
    else {
      this.importerAccount.secrets = {} as {username: string, password: string, otp_secret_key: string}
    }
  }

  getAvailableSupplierSystem(customerId: string) {
    this.customersService.getSupplierSystems(customerId).pipe(take(1)).subscribe((res: any) => {
      this.availableSystemInfoList = res
    })
  }

  addImporter(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>) {
    const modal: NzModalRef = this.modal.create({
      nzTitle: 'Add importer configuration',
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzData: this.customer,
    });
  }

  newCredentials(ref: NzModalRef) {
    this.accountCRUD = true
    if(this.importerAccount) {
      this.customersService.addImporterAccounts(this.customer.pk, this.importerAccount).pipe(take(1)).subscribe(
        {
          next: (res: any) => {
            this.verifyCredentials(res.id)
            this.accountCRUD = false
            this.getAvailableSupplierSystem(this.customer.pk)
            this.getImporterAccounts(this.customer.pk)
            this.supplierSystem = undefined
            ref.close()
          },
          error: (error: any) => {
            this.error = error.error
            this.accountCRUD = false
          },
          complete: () => {
            this.accountCRUD = false
          }
        }
      )
    }
  }

  isCreateDisabled(): boolean {
    let disabled = true
    if(this.supplierSystem) {
      if(this.supplierSystem.supplier_system_auth_id === 'grp') {
        disabled = !(this.importerAccount?.supplier_system_auth_id && this.importerAccount?.secrets?.username && this.importerAccount?.secrets?.password && this.importerAccount?.secrets?.otp_secret_key || this.importerAccount?.credentials_id)
      } else {
        disabled = !(this.importerAccount?.supplier_system_auth_id && this.importerAccount?.secrets?.username && this.importerAccount?.secrets?.password)
      }
    }
    return disabled
  }

  closeModal(ref: NzModalRef) {
    this.supplierSystem = undefined
    this.error = undefined
    this.importerAccount = undefined
    ref.close()
  }

  getImporterAccounts(custId: string) {
    this.accLoading = true
    this.customersService.getImporterAccounts(custId).pipe(take(1)).subscribe((res) => {
      this.accounts = res;
      this.accLoading = false
    })
  }

  verifyCredentials(importerAccountId: string) {
    this.customersService.verifyCredentials(this.customer.pk, importerAccountId).subscribe()
  }

  deleteAccount(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, item: any) {
    const modal: NzModalRef = this.modal.create({
      nzTitle: 'Delete importer account',
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzData: item,
    });
    modal.afterClose.subscribe(() => {
      this.deleteTextValue = ''
      this.error = undefined
    });
  }

  delete(ref: NzModalRef) {
    this.accountCRUD = true
    let importerAccountId = ref.getConfig().nzData.id
    this.customersService.deleteAccount(this.customer.pk, importerAccountId).pipe(take(1)).subscribe({
      next: (res: any) => {
        this.accountCRUD = false
        this.getAvailableSupplierSystem(this.customer.pk)
        this.getImporterAccounts(this.customer.pk)
        ref.close()
      },
      error: (err: any) => {
        this.error = err.error
        this.accountCRUD = false
      },
      complete: () => {
        this.accountCRUD = false
      }
    })
  }
}


