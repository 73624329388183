<h3 class="header-title">Users</h3>
<nz-table
  [nzLoading]="isLoading"
  [nzData]="users"
  [nzShowPagination]="false"
  [nzFrontPagination]="false"
  [nzSize]="'small'"
>
  <thead>
  <tr>
    <th>Name</th>
    <th>Email</th>
    <th>Last visit</th>
    <th>Visits</th>
    <th>Status</th>
    <th>Role</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let user of users">
    <td>{{ user.name}}</td>
    <td>{{ user.email }}</td>
    <td>{{ user.last_visit }}</td>
    <td>{{ user.visits }}</td>
    <td>{{ user.status }}</td>
    <td>{{ user.role }}</td>
  </tr>
  </tbody>
</nz-table>
