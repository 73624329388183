import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzInputModule} from "ng-zorro-antd/input";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'search-input',
  standalone: true,
  imports: [NzIconModule, NzInputModule, FormsModule],
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss'
})
export class SearchInputComponent {

  @Input() placeholder: string = "Search"

  @Output() searchValue = new EventEmitter<string>();
  searchInputValue: string = "";

  setSearchValue = () => {
    this.searchValue.emit(this.searchInputValue.toLowerCase())
  }

}
