import {Component, inject, OnInit} from '@angular/core';
import {CustomersService} from "../../services/customers/customers.service";
import {switchMap} from 'rxjs';
import {NzTableModule} from "ng-zorro-antd/table";
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'leads',
  standalone: true,
  imports: [CommonModule, NzTableModule, NzSpinModule],
  templateUrl: './leads.component.html',
  styleUrl: './leads.component.scss'
})
export class LeadsComponent implements OnInit {
  customersService = inject(CustomersService)
  users: any
  isLoading = true;

  ngOnInit() {
    this.customersService.getCustomers().pipe(
      switchMap((res) => {
        const defaultCustomer = res.find((item: {
          admin_email: string
        }) => item.admin_email === "operations@yomagroup.de");
        return this.customersService.getUsers(defaultCustomer.pk);
      })
    ).subscribe({
      next: (users) => {
        this.users = users;
        this.isLoading = false;
      },
      error: (err) => {
        console.error('Error occurred:', err);
        this.isLoading = false;
      }
    });
  }
}
