<nz-card>
    <div class="switch">
        <h5 nz-typography>{{customerFeature.name}}</h5>
        <nz-switch [(ngModel)]="!customerFeature.toggled_off"
            nzCheckedChildren="ON" nzUnCheckedChildren="OFF"
            [nzLoading]="activating"
            (ngModelChange)="enableFeature()"></nz-switch>
    </div>
    <nz-divider></nz-divider>
    <div>
        <div class="feature-data-item">
            <nz-form-label [nzSpan]="10" nzNoColon>Renewal period</nz-form-label>
            <nz-form-item>
                <nz-form-control [nzSpan]="24" nzHasFeedback nzErrorTip="Renewal period is required">
                    <nz-input-group [nzSuffix]="periodSuffix">
                        <input nz-input [(ngModel)]="customerFeature.renewal_period"
                            placeholder="Renewal period" [disabled]="!(!customerFeature.deleted && editFeature)"
                            name="feature_renewal_period"/>
                    </nz-input-group>
                    <ng-template #periodSuffix>
                        <span class="input-suffix">Months</span>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div class="feature-data-item">
            <nz-form-label [nzSpan]="10" nzNoColon>Price</nz-form-label>
            <nz-form-item>
                <nz-form-control [nzSpan]="24" nzHasFeedback nzErrorTip="Price is required">
                    <nz-input-group [nzSuffix]="currencySuffix">
                        <input nz-input [(ngModel)]="customerFeature.price"
                            placeholder="Price" [disabled]="!(!customerFeature.deleted && editFeature)"
                            name="feature_price"
                        />
                    </nz-input-group>
                    <ng-template #currencySuffix>
                        <span class="input-suffix">€</span>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <nz-divider></nz-divider>
    <div class="actions">
        <button nz-button nzType="text" *ngIf="!(!customerFeature.deleted && editFeature) else saveFeature"
            (click)="edit()"
            [disabled]="customerFeature.deleted || activating">
            <span nz-icon nzType="edit"></span>
            Edit
        </button>
        <ng-template #saveFeature>
            <button nz-button nzType="text"
            (click)="update()"
            [nzLoading]="isLoading">
            <span nz-icon nzType="save"></span>
            Update
        </button>
        </ng-template>
    </div>
</nz-card>
