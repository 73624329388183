<div class="header-container">
  <h1>Mapping</h1>
  <div class="actions">
    <nz-input-group [nzSize]="'large'" [nzSuffix]="suffixIconSearch">
      <input [(ngModel)]="searchInput" type="text" nz-input placeholder="Search for a mapping" (keydown.enter)="search(searchInput)" />
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <span class="search" nz-icon nzType="search" (click)="search(searchInput)"></span>
    </ng-template>
    <button
      class="add-btn"
      nz-button
      nzType="primary"
      nzDanger
      [nzSize]="'large'"
      (click)="openMappingModal(tplContent, tplFooter)"
      >
      <span nz-icon nzType="plus"></span>
      Add a mapping
    </button>
    <!-- <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click" [nzSize]="'large'">
      Actions
      <span nz-icon nzType="more"></span>
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item>
          <a>1st item</a>
        </li>
        <li nz-menu-item>
          <a>2nd item</a>
        </li>
        <li nz-menu-item>
          <a>3rd item</a>
        </li>
      </ul>
    </nz-dropdown-menu> -->
  </div>
</div>
<div class="content">
  <nz-table
    #rowSelectionTable
    [nzData]="listOfData"
    [nzLoading]="loading"
    [nzSize]="'small'"
    [nzFrontPagination]="false"
    [nzTotal]="10"
    [nzPaginationType]="'small'"
    [nzShowPagination]="true"
    id="group-table"
    class="yoma-table"
  >
    <thead>
      <tr>
        <th
        [nzChecked]="checked"
        nzLabel="Select all"
        (nzCheckedChange)="onAllChecked($event)"
      ></th>
        <th>Name</th>
        <th>ID</th>
        <th>Look up</th>
        <th>Mapping</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of rowSelectionTable.data">
        <td
          [nzChecked]="setOfCheckedId.has(data.pk!)"
          [nzLabel]="data.supplier_system_name!"
          (nzCheckedChange)="onItemChecked(data.pk!, $event)"
        ></td>
        <td>{{ data.supplier_system_name }}</td>
        <td>{{ data.supplier_system_id }}</td>
        <td>{{ data.lookup_key }}</td>
        <td>{{ data.order_type }}</td>
        <td>
          <button nz-button nzType="text">
            <span
              nz-icon
              nzType="edit"
              nzTheme="outline"
              (click)="openMappingModal(tplContent, tplFooter, data)"
            ></span>
          </button>
          <button nz-button nzType="text" (click)="deleteMappingModal(tplDeleteModal, tplDeleteModalFooter, data)">
            <span nz-icon nzType="delete" nzTheme="outline"></span>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>

  <ng-template #tplContent let-ref="modalRef">
    <form nz-form>
      <nz-form-label nzRequired nzNoColon>Supplier system</nz-form-label>
      <nz-form-item>
        <nz-form-control
          nzHasFeedback
          nzErrorTip="Supplier system is required"
          >
          <nz-select
            nzPlaceHolder="Supplier system"
            [(ngModel)]="ref.getConfig().nzData.supplier_system_id"
            name="supplier_system_id"
            required="true"
            >
            <nz-option
              *ngFor="let supplierSystem of supplierSystems | keyvalue"
              nzLabel="{{ supplierSystem.value }} ({{ supplierSystem.key }})"
              nzValue="{{ supplierSystem.key }}"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <div class="lookup">
        <div>
          <nz-form-label nzRequired>Look up</nz-form-label>
          <nz-form-item>
            <nz-form-control nzHasFeedback nzErrorTip="Look up is required">
              <input
                nz-input
                [(ngModel)]="ref.getConfig().nzData.lookup_key"
                name="lookup_key"
                required="true"
                class="look-up-input"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div>
          <nz-form-label nzRequired>Order Type</nz-form-label>
          <nz-form-item>
            <nz-form-control nzHasFeedback nzErrorTip="Order Type is required">
              <nz-select
                nzPlaceHolder="Order Type"
                [(ngModel)]="ref.getConfig().nzData.order_type"
                name="order_type"
                required="true"
              >
                <nz-option nzLabel="Stock" [nzValue]="'stock'"></nz-option>
                <nz-option nzLabel="Customer" [nzValue]="'customer'"></nz-option>
                <nz-option nzLabel="Other" [nzValue]="'other'"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #tplFooter let-ref="modalRef">
    <button nz-button (click)="ref.close()">Cancel</button>
    <button
      nzType="primary"
      nzDanger
      [nzLoading]="loading"
      [disabled]="
        !(
          ref.getConfig().nzData.lookup_key &&
          ref.getConfig().nzData.order_type &&
          ref.getConfig().nzData.supplier_system_id
        )
      "
      nz-button (click)="saveMapping(ref)"
    >
      {{ ref.getConfig().nzData.pk ? "Save changes" : "Add mapping" }}
    </button>
  </ng-template>
</div>

<ng-template #tplDeleteModal let-ref="modalRef">
  <p>
    This will <b>permanently remove</b> the mapping and all of its associated data  from the system. This cannot be undone.
  </p>
  <p>Enter “Delete” and the mappings ID into the field below to proceed:</p>
  <form nz-form>
    <nz-form-item>
      <nz-form-control nzHasFeedback nzErrorTip="Feature name is required">
        <input class="form-item-bordered" nz-input [(ngModel)]="deleteTextValue" name="feature_delete_text"
               required="true" [placeholder]="'Delete ' + ref.getConfig().nzData.supplier_system_id"/>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>
<ng-template #tplDeleteModalFooter let-ref="modalRef">
  <button nz-button (click)="ref.close()">Cancel</button>
  <button nz-button (click)="deleteMapping(ref)" [nzLoading]="loading" nzDanger nzType="primary"
    [disabled]="deleteTextValue != ('Delete ' + ref.getConfig().nzData.supplier_system_id)"
    >Delete</button>
</ng-template>