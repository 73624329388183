<div class="feature-block">
  <div class="feature-header">
    <span class="feature-title">{{feature.name}}</span>
    <div class="feature-actions-container">
      <button nz-button nzType="default" (click)="handleEditClick(feature.pk)">
        <span nz-icon nzType="edit" nzTheme="outline"></span>
      </button>
      <button nz-button nzType="default" (click)="handleDeleteClick(feature.pk)">
        <span nz-icon nzType="delete" nzTheme="outline"></span>
      </button>
    </div>
  </div>
  <p class="feature-description">{{feature.description}}</p>
<!--  <span class="feature-price">Renewal Time: {{feature.renewal_period}} Month</span>-->
  <div class="feature-bottom-container">
    <div class="feature-bottom-item">
      <div class="feature-bottom-title">
        Price
      </div>
      <nz-input-group [nzSuffix]="currencySuffix">
        <input type="text" nz-input placeholder="0" [value]="feature.price" readonly/>
      </nz-input-group>
      <ng-template #currencySuffix>
        <span class="input-suffix">€</span>
      </ng-template>
    </div>
    <div class="feature-bottom-item">
      <div class="feature-bottom-title">
        Renewal period
      </div>
      <nz-input-group [nzSuffix]="periodSuffix">
        <input type="text" nz-input placeholder="0" [value]="feature.renewal_period" readonly/>
      </nz-input-group>
      <ng-template #periodSuffix>
        <span class="input-suffix">Months</span>
      </ng-template>
    </div>
  </div>
</div>
