import {HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {inject} from "@angular/core";
import {SocialAuthService, SocialUser} from "@abacritt/angularx-social-login";
import {switchMap} from "rxjs";

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(SocialAuthService)
  return authService.authState.pipe(
    switchMap((user: SocialUser) => {
      const request = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${user.idToken}`),
      });
      return next(request)
    })
  )
};
