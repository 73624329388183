import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class VariablesService {

  constructor(private http: HttpClient) { }

  getStandardVariableNames(locale: string): Observable<any>{
    return this.http.get(`${environment.yomaApiURL}/resources/i18n/${locale}`);
  }

  setStandardVariableNames(variableNames: any, locale: string): Observable<any>{
    return this.http.put(`${environment.yomaApiURL}/resources/i18n/${locale}`, variableNames);
  }

}
