import {Component, inject, OnInit, TemplateRef, SimpleChanges, OnChanges} from '@angular/core';
import {CustomersService} from "../../services/customers/customers.service";
import {CustomerComponent} from "./customer/customer.component";
import {CommonModule} from '@angular/common';
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzModalModule, NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {FormsModule} from "@angular/forms";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzSpinModule} from "ng-zorro-antd/spin";
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzOptionComponent, NzSelectComponent, NzSelectModule} from "ng-zorro-antd/select";
import {FeaturesService} from "../../services/features/features.service";
import {NzTableModule} from "ng-zorro-antd/table";
import {NzIconDirective} from "ng-zorro-antd/icon";
import {SearchInputComponent} from "../search-input/search-input.component";
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import { NzInputModule } from 'ng-zorro-antd/input';
import { Router } from '@angular/router';
import { Buffer } from 'buffer';


@Component({
  selector: 'customers',
  standalone: true,
  imports: [
    CustomerComponent,
    CommonModule,
    FormsModule,
    NzButtonModule, NzFormModule, FormsModule, NzModalModule, NzSpinModule, NzOptionComponent, NzSelectModule, NzSwitchModule, NzTableModule, NzIconDirective, SearchInputComponent, NzTypographyModule, NzInputModule
  ],
  templateUrl: './customers.component.html',
  styleUrl: './customers.component.scss'
})
export class CustomersComponent implements OnInit {
  customersService = inject(CustomersService);
  featuresService = inject(FeaturesService);
  router = inject(Router)
  listOfCustomers: any[] = [];
  filteredListOfCustomers: any[] = [];
  customer: any = {};
  customerEditId: string = "";
  modal = inject(NzModalService);
  isLoading = false;
  isModalLoading = false;

  searchValue = "";

  checked = false;
  loading = false;
  indeterminate = false;
  setOfCheckedId = new Set<number>();

  deleteTextValue = "";
  deleteText = "delete";

  // activeFeatures: any[] = [];

  ngOnInit(): void {
    this.getCustomers();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCustomers.filter(({disabled}) => !disabled);
    this.checked = listOfEnabledData.every(({id}) => this.setOfCheckedId.has(id));
    this.indeterminate = listOfEnabledData.some(({id}) => this.setOfCheckedId.has(id)) && !this.checked;
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.listOfCustomers
      .filter(({disabled}) => !disabled)
      .forEach(({pk}) => this.updateCheckedSet(pk, checked));
    this.refreshCheckedStatus();
  }


  getCustomers() {
    this.isLoading = true
    this.customersService.getCustomers().subscribe(res => {
      this.listOfCustomers = res;
      this.isLoading = false
    })
  }

  // getCustomersFeatures(customerId: string) {
  //   this.customersService.getCustomersFeatures(customerId).subscribe(res => {
  //     //delete after updating API
  //     let data: any[] = [];
  //     data = res
  //     this.currentCustomerFeatures = data.filter(item => !item.deleted);
  //   })
  // }

  openCustomerDeleteModal(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, customerDeleteId: string){
    this.customer = this.listOfCustomers.find(customer => {
      return customer.pk === customerDeleteId;
    })
    this.deleteText = ("delete " + this.customer.company_name).trim()
    const modal: NzModalRef = this.modal.create({
      nzTitle: 'Delete tenant',
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzOnCancel: () => this.handleModalClose()
    });
  }

  deleteCustomer(ref: any, customerId: string) {
    ref.destroy();
    this.isLoading = true
    this.customersService.deleteCustomer(customerId).subscribe(() => {
      this.isLoading = false
      this.customer = {}
      this.customerEditId = ""
      this.getCustomers();
    })
  }

  handleModalClose(ref?: any) {
    if (ref) {
      ref.destroy()
    }
    this.deleteTextValue = "";
    this.getCustomers();
  }

  handleSearchClick(searchValue: string) {
    this.searchValue = searchValue;
    if (this.searchValue) {
      this.filteredListOfCustomers = this.listOfCustomers.filter(item => item.company_name.toLowerCase().includes(searchValue.toLowerCase()))
    }
  }

  customerLink(data: any) {
    let id = Buffer.from(data.pk).toString('base64')
    this.router.navigateByUrl(`tenants/${id}`)
  }

  customerCreateLink(){
    this.router.navigateByUrl(`tenants/create`)
  }

}
