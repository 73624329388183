export const environment = {
  production: false,
  googleClientId: "325355057504-v7hpvegu8936vv7ed1neecn9i5uhujgi.apps.googleusercontent.com",
  yomaApiURL: "https://api.admin.dev.yoma-track-trace.com",

  // sandbox yomaImporterApi
  // yomaImporterApi: 'https://yo3zus6uh6.execute-api.eu-central-1.amazonaws.com/v1'

  // development yomaImporterApi
  yomaImporterApi: 'https://importer-api.dev.yomagroup.info'
}
