<!-- <div class="send-request">
  <button
    nz-button
    nzType="primary"
    [disabled]="setOfCheckedId.size === 0"
    [nzLoading]="loading"
    (click)="sendRequest()"
  >
    Send Request
  </button>
  <span>Selected {{ setOfCheckedId.size }} items</span>
</div> -->
<nz-table
  #rowSelectionTable
  [nzFrontPagination]="false"
  [nzData]="listOfData"
  [nzLoading]="loading"
  [nzSize]="'small'"
  id="group-table"
>
  <thead>
    <tr>
      <th
        [nzChecked]="checked"
        nzLabel="Select all"
        (nzCheckedChange)="onAllChecked($event)"
      ></th>
      <th>Group ID</th>
      <th>Group name</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of rowSelectionTable.data">
      <td
        [nzChecked]="setOfCheckedId.has(data.id)"
        [nzLabel]="data.name"
        (nzCheckedChange)="onItemChecked(data.id, $event)"
      ></td>
      <td>{{ data.id }}</td>
      <td>{{ data.name }}</td>
      <td>
        <button nz-button nzType="text">
            <span nz-icon nzType="edit" nzTheme="outline"></span>
        </button>
        <button nz-button nzType="text">
            <span nz-icon nzType="delete" nzTheme="outline"></span>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>
