[
    {
      "id": "pab-de000405-41352-1",
      "name": "Wollnikom GmbH"
    },
    {
      "id": "pab-de-dia-1",
      "name": "DIA / APZ"
    },
    {
      "id": "pab-de-intrexx-1",
      "name": "Intrexx - Mann-Auftragsportal"
    },
    {
      "id": "pas-de-premium-zulasser-1",
      "name": "Premium Zulasser"
    },
    {
      "id": "pas-de-ps-team-1",
      "name": "PS Team"
    },
    {
      "id": "pas-de-tuev-rheinland-api-1",
      "name": "API Tüv Rheinland Plus"
    },
    {
      "id": "pcd-de-care-43",
      "name": "Care System"
    },
    {
      "id": "pcd-de-webcare-44",
      "name": "webcare"
    },
    {
      "id": "pcd-dms-liste-heermann-bmw-sgate-1",
      "name": "DMS_BMW_SGATE_AH_HEERMANN"
    },
    {
      "id": "pcd-dms-liste-pfohe-salesflow-1",
      "name": "DMS Liste Pfohe"
    },
    {
      "id": "pcd-dms-liste-schwabengarage-salesflow-2",
      "name": "DMS Liste Schwabengarage"
    },
    {
      "id": "pcd-dms-liste-yoma",
      "name": "DMS_YOMA"
    },
    {
      "id": "pcd-dms-split-liste-yoma",
      "name": "DMS_YOMA_SPLIT"
    },
    {
      "id": "pcd-ef-snowflake-ford",
      "name": "Emil Frey Snowflake Ford"
    },
    {
      "id": "pcr-de002268-20537-1",
      "name": "Star Car GmbH Kraftfahrzeugvermietung"
    },
    {
      "id": "pfm-de002267-81925-1",
      "name": "BayWa AG"
    },
    {
      "id": "plc-de000001-61352-1",
      "name": "Deutsche Leasing AG"
    },
    {
      "id": "plc-de000002-41460-1",
      "name": "LeasePlan Deutschland GmbH"
    },
    {
      "id": "plc-de000478-82041-1",
      "name": "Arval Deutschland GmbH"
    },
    {
      "id": "plc-de000479-82041-1",
      "name": "Mobility Concept GmbH"
    },
    {
      "id": "plc-de000480-22529-1",
      "name": "ALD AutoLeasing D GmbH"
    },
    {
      "id": "plc-de000480-22529-2",
      "name": "ALD Lease Finanz GmbH"
    },
    {
      "id": "plc-de000553-38112-1",
      "name": "Volkswagen Leasing GmbH"
    },
    {
      "id": "plc-de002194-42277-1",
      "name": "akf Servicelease GmbH"
    },
    {
      "id": "plc-de002266-65760-2",
      "name": "ARI Fleet Germany GmbH"
    },
    {
      "id": "plc-de002269-94036-1",
      "name": "Raiffeisen Impuls Finance & Lease GmbH"
    },
    {
      "id": "plo-altmann-logistik-1",
      "name": "Altmann Logistik"
    },
    {
      "id": "plo-blg-car-center-25",
      "name": "BLG Schnittstelle (Data Wizard API)"
    },
    {
      "id": "plo-blg-jcars-36",
      "name": "BLG jc@rs Portal"
    },
    {
      "id": "plo-frankenbach-logistik-46",
      "name": "Frankenbach Logistik"
    },
    {
      "id": "plo-gefco-portaile_automotive-27",
      "name": "GEFCO Portaile Automotive"
    },
    {
      "id": "plo-helf-hais-26",
      "name": "Helf Logistik GmbH"
    },
    {
      "id": "plo-mosolf-transporte-37",
      "name": "MOSOLF Transporte GmbH"
    },
    {
      "id": "plo-rh-autologistik-38",
      "name": "R&H Autologistik GmbH & Co. KG"
    },
    {
      "id": "plo-srl-logistiker-40",
      "name": "SRL Logistiker System"
    },
    {
      "id": "plo-yilmaz-logistik-39",
      "name": "Yilmaz Logistik Group"
    },
    {
      "id": "pma-bmw-sgate-41",
      "name": "BMW Herstellersystem"
    },
    {
      "id": "pma-de-abarth-23",
      "name": "ABARTH Herstellersystem"
    },
    {
      "id": "pma-de-afab-42",
      "name": "Mercedes Nutzfahrzeuge Herstellersystem"
    },
    {
      "id": "pma-de-alpina-1",
      "name": "Alpina Herstellersystem"
    },
    {
      "id": "pma-de-aston-martin-24",
      "name": "ASTON-MARTIN Herstellersystem"
    },
    {
      "id": "pma-de-bentley-25",
      "name": "BENTLEY Herstellersystem"
    },
    {
      "id": "pma-de-chevrolet-26",
      "name": "CHEVROLET Herstellersystem"
    },
    {
      "id": "pma-de-chrysler-1",
      "name": "CHRYSLER Herstellersystem"
    },
    {
      "id": "pma-de-cronos-1",
      "name": "Cronos Opel, Peugeot, Citroen, DS Herstellersystem"
    },
    {
      "id": "pma-de-cross-1",
      "name": "CROSS Volkswagen Vertriebsbetreuungsgesellschaft mbH"
    },
    {
      "id": "pma-de-dacia-27",
      "name": "DACIA Herstellersystem"
    },
    {
      "id": "pma-de-daihatsu-28",
      "name": "DAIHATSU Herstellersystem"
    },
    {
      "id": "pma-de-dcs-16",
      "name": "SUZUKI Herstellersystem"
    },
    {
      "id": "pma-de-dealerportal-3",
      "name": "HYUNDAI Herstellersystem"
    },
    {
      "id": "pma-de-dealerportal-5",
      "name": "KIA Herstellersystem"
    },
    {
      "id": "pma-de-disposhop-15",
      "name": "SKODA Herstellersystem (Altes System, nicht mehr aktiv)"
    },
    {
      "id": "pma-de-distrinet-13",
      "name": "RENAULT / DACIA Herstellersystem"
    },
    {
      "id": "pma-de-ducati-1",
      "name": "DUCATI Herstellersystem"
    },
    {
      "id": "pma-de-e-com-2",
      "name": "HONDA Herstellersystem Cosmos"
    },
    {
      "id": "pma-de-e-motive-6",
      "name": "MAZDA Herstellersystem"
    },
    {
      "id": "pma-de-fisker-1",
      "name": "Fisker Herstellersystem"
    },
    {
      "id": "pma-de-ford-votdde",
      "name": "Ford Flat Files"
    },
    {
      "id": "pma-de-genesis-1",
      "name": "Genesis Herstellersystem"
    },
    {
      "id": "pma-de-globalconnect-10",
      "name": "OPEL Herstellersystem"
    },
    {
      "id": "pma-de-go-7",
      "name": "MERCEDES BENZ / SMART Herstellersystem"
    },
    {
      "id": "pma-de-goupil-1",
      "name": "GOUPIL Herstellersystem"
    },
    {
      "id": "pma-de-ineos-1",
      "name": "Ineos Herstellersystem"
    },
    {
      "id": "pma-de-infiniti-29",
      "name": "INFINITI Herstellersystem"
    },
    {
      "id": "pma-de-iveco-22",
      "name": "IVECO Herstellersystem"
    },
    {
      "id": "pma-de-kawasaki-1",
      "name": "KAWASAKI Herstellersystem"
    },
    {
      "id": "pma-de-ktm-1",
      "name": "KTM Herstellersysteme"
    },
    {
      "id": "pma-de-kymco-1",
      "name": "KYMCO Herstellersystem"
    },
    {
      "id": "pma-de-lexus-30",
      "name": "LEXUS Herstellersystem"
    },
    {
      "id": "pma-de-link-entry-1",
      "name": "ALFA ROMEO / JEEP / FIAT Herstellersystem / Abarth"
    },
    {
      "id": "pma-de-lucid-1",
      "name": "Lucid Herstellersystem"
    },
    {
      "id": "pma-de-madac-2",
      "name": "CITROEN Herstellersystem"
    },
    {
      "id": "pma-de-madap-11",
      "name": "PEUGEOT Herstellersystem"
    },
    {
      "id": "pma-de-maserati-21",
      "name": "MASERATI Herstellersystem"
    },
    {
      "id": "pma-de-maxus-1",
      "name": "MAXUS Herstellersystem"
    },
    {
      "id": "pma-de-mg-1",
      "name": "MG Herstellersystem"
    },
    {
      "id": "pma-de-mitsubishi-31",
      "name": "MITSUBISHI Herstellersystem"
    },
    {
      "id": "pma-de-mubea-1",
      "name": "MUBEA Herstellersystem"
    },
    {
      "id": "pma-de-nadin-14",
      "name": "SEAT Nadin Herstellersystem"
    },
    {
      "id": "pma-de-nadin-45",
      "name": "Skoda Nadin Herstellersystem"
    },
    {
      "id": "pma-de-nadin-51",
      "name": "VW Nadin Herstellersystem"
    },
    {
      "id": "pma-de-nevada-web-18",
      "name": "VW / AUDI Herstellersystem"
    },
    {
      "id": "pma-de-nio-1",
      "name": "NIO Herstellersystem"
    },
    {
      "id": "pma-de-nivos-9",
      "name": "NISSAN Herstellersystem"
    },
    {
      "id": "pma-de-opel-api-1",
      "name": "Opel Global Connect DMS Integration API (Test Only!!!)"
    },
    {
      "id": "pma-de-ora-1",
      "name": "ORA Herstellersystem"
    },
    {
      "id": "pma-de-piaggio-1",
      "name": "PIAGGIO Herstellersystem"
    },
    {
      "id": "pma-de-polestar-61",
      "name": "Polestar Herstellersystem"
    },
    {
      "id": "pma-de-ppn-12",
      "name": "PORSCHE Herstellersystem"
    },
    {
      "id": "pma-de-rivian-1",
      "name": "RIVIAN Herstellersystem"
    },
    {
      "id": "pma-de-saic-1",
      "name": "SAIC Herstellersystem"
    },
    {
      "id": "pma-de-sps-8",
      "name": "MINI / BMW Herstellersystem"
    },
    {
      "id": "pma-de-ssangyong-33",
      "name": "SSANGYONG Herstellersystem"
    },
    {
      "id": "pma-de-subaru-32",
      "name": "SUBARU Herstellersystem"
    },
    {
      "id": "pma-de-tesla-20",
      "name": "TESLA Herstellersystem"
    },
    {
      "id": "pma-de-toyota-19",
      "name": "TOYOTA Herstellersystem"
    },
    {
      "id": "pma-de-triumph-1",
      "name": "TRIUMPH Herstellersystem"
    },
    {
      "id": "pma-de-vista1-1",
      "name": "FORD Herstellersystem"
    },
    {
      "id": "pma-de-vista2-4",
      "name": "JAGUAR / LAND ROVER Herstellersystem"
    },
    {
      "id": "pma-de-vista3-17",
      "name": "VOLVO Herstellersystem"
    }
   ]