import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzInputDirective, NzInputGroupComponent, NzInputGroupWhitSuffixOrPrefixDirective} from "ng-zorro-antd/input";

@Component({
  selector: 'feature',
  standalone: true,
  imports: [
    NzButtonModule,
    NzIconModule,
    NzInputDirective,
    NzInputGroupComponent,
    NzInputGroupWhitSuffixOrPrefixDirective
  ],
  templateUrl: './feature.component.html',
  styleUrl: './feature.component.scss'
})
export class FeatureComponent implements OnInit{
  @Input() feature: any = {}
  @Output() featureDeleteId = new EventEmitter<string>();
  @Output() featureEditId = new EventEmitter<string>();

  ngOnInit(): void {
  }
  handleEditClick(featureID: any){
    this.featureEditId.emit(featureID)
  }

  handleDeleteClick(featureID: any){
    this.featureDeleteId.emit(featureID)
  }
}
