import {Component, inject, Input, OnInit} from '@angular/core';
import {Customer} from "../../../model/models";
import {CustomersService} from "../../../services/customers/customers.service";
import {NzTableModule} from "ng-zorro-antd/table";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'customer-users',
  standalone: true,
  imports: [NzTableModule, CommonModule],
  templateUrl: './customer-users.component.html',
  styleUrl: './customer-users.component.scss'
})
export class CustomerUsersComponent implements OnInit {
  @Input() customer!: Customer

  users: any[] = [];
  isLoading = true;

  customerService = inject(CustomersService);

  ngOnInit() {
    this.customerService.getUsers(this.customer.pk).subscribe(res => {
      this.users = res;
      this.isLoading = false
    })
  }
}
