import { Injectable } from '@angular/core';
import Groups from './GROUP_MOCK_DATA.json'
import DataProviderSystems from './DATAPROVIDER_MOCK_DATA.json'
import Feature from './FEATURES_MOCK_DATA.json'

@Injectable({
  providedIn: 'root'
})
export class MockDataService {

  getGroups() {
    return Groups
  }

  getDataProviderSystems() {
    return DataProviderSystems
  }

  getFeatures() {
    return  Feature
  }
}
