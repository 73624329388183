import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import { Buffer } from 'buffer';
import {environment} from "../../../environments/environment";
import { Mapping } from '../../model/models';

@Injectable({
  providedIn: 'root'
})
export class MappingService {

  constructor(private http: HttpClient) { }

  getVehicleOrderTypeMappings(): Observable<any>{
    return this.http.get(`${environment.yomaApiURL}/mappings/vehicleOrderType`);
  }

  getSupplierSystems(): Observable<any>{
    return this.http.get(`${environment.yomaApiURL}/supplierSystems`);
  }

  deleteMapping(mappingId: any): Observable<any>{
    let encoded = Buffer.from(mappingId).toString('base64')
    return this.http.delete(`${environment.yomaApiURL}/mappings/vehicleOrderType/${encoded}`);
  }

  putMapping(mapping: Mapping): Observable<any>{
    if (mapping.pk) {
      let encoded = Buffer.from(mapping.pk).toString('base64')
      return this.http.patch(`${environment.yomaApiURL}/mappings/vehicleOrderType/${encoded}`, mapping);
    } else {
      return this.http.put(`${environment.yomaApiURL}/mappings/vehicleOrderType`, mapping);
    }
  }
}
