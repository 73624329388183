<!--<div class="features" *ngIf="!isLoading">-->
<div class="header-container">
  <h3 class="header-title">Features</h3>
  <div class="actions">
    <search-input
      [placeholder]="'Search feature'"
      (searchValue)="handleSearchClick($event)"
    ></search-input>
    <button class="primary-button" nz-button nzType="primary" nzDanger nzSize="large"
            (click)="openFeatureModal(tplContent, tplFooter)">
      <span nz-icon nzType="plus" nzTheme="outline"></span>
      Create new feature
    </button>
  </div>
</div>
<div class="features-wrapper">
  <div *ngIf="isLoading" class="loader-container">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </div>
  <div class="features-container" *ngIf="!isLoading">
    <feature
      *ngFor="let item of searchValue? filteredListOfFeatures: listOfFeatures"
      [feature]="item"
      (featureDeleteId)="openDeleteFeatureModal(tplDeleteModal, tplDeleteModalFooter, $event)"
      (featureEditId)="openFeatureModal(tplContent, tplFooter, $event)"
    ></feature>
  </div>
</div>
<ng-template #tplContent>
  <form nz-form [formGroup]="featureForm">
    <nz-form-label class="feature-form-label" nzRequired>Feature name</nz-form-label>
    <nz-form-item>
      <nz-form-control nzHasFeedback nzErrorTip="Feature name is required">
        <input nz-input formControlName="name" name="feature_name" required="true"
               placeholder="My new amazing feature"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-label class="feature-form-label" nzRequired>Alias</nz-form-label>
    <nz-form-item>
      <nz-form-control nzHasFeedback nzErrorTip="Feature name is required">
        <input nz-input formControlName="alias" name="feature_alias" required="true"
               placeholder="Alias"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-label class="feature-form-label" nzRequired>Teaser Subline</nz-form-label>
    <nz-form-item>
      <nz-form-control nzHasFeedback nzErrorTip="Teaser Subline name is required">
        <input nz-input formControlName="name_subtitle" name="feature_teaser_subline" required="true"
               placeholder="Teaser Subline"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-label class="feature-form-label" nzRequired>Description</nz-form-label>
    <nz-form-item>
        <textarea nz-input formControlName="description"
                  placeholder="A short and brief explanation what this feature does" nzAutosize
                  style="width: 100%; min-height: 150px" name="feature_desc"></textarea>
    </nz-form-item>
    <div class="feature-bottom-container">
      <div class="feature-form-item">
        <nz-form-label class="feature-form-label" nzRequired>Price</nz-form-label>
        <nz-form-item>
          <nz-form-control nzHasFeedback nzErrorTip="Renewal period is required">
            <nz-input-group [nzSuffix]="currencySuffix">
              <input type="text" nz-input placeholder="0" name="feature_price" formControlName="price"/>
            </nz-input-group>
            <ng-template #currencySuffix>
              <span class="input-suffix">€</span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="feature-form-item">
        <nz-form-label class="feature-form-label" nzRequired>Renewal period</nz-form-label>
        <nz-form-item>
          <nz-form-control nzHasFeedback nzErrorTip="Feature price is required">
            <nz-input-group [nzSuffix]="periodSuffix">
              <input type="text" nz-input placeholder="0" name="feature_renewal_period"
                     formControlName="renewal_period"/>
            </nz-input-group>
            <ng-template #periodSuffix>
              <span class="input-suffix">Months</span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <nz-form-label class="feature-form-label">Pricing Information</nz-form-label>
    <nz-form-item>
      <nz-form-control nzHasFeedback>
        <input nz-input formControlName="pricing_information" name="feature_pricing_information"
               placeholder="Pricing Information"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <label nz-checkbox formControlName="show_on_frontpage">
        <span>Show on frontpage</span>
      </label>
    </nz-form-item>
  </form>
</ng-template>
<ng-template #tplFooter let-ref="modalRef">
  <button nz-button (click)="handleModalClose(ref)" class="form-button">Cancel</button>
  <button
    nzType="primary"
    [nzLoading]="false"
    [disabled]="!featureForm.valid"
    nz-button
    class="form-button"
    nzDanger
    (click)="saveFeature(ref)">{{featureEditId ? "Edit feature" : "Add feature"}}
  </button>
</ng-template>

<ng-template #tplDeleteModal>
  <p>This will <b>permanently removeM</b> the feature <b>{{feature.name}}</b> and all of its associated data and
    orders from the system. This cannot be undone.</p>
  <p>Enter “Delete” and the feature name into the field below to proceed:</p>
  <form nz-form>
    <nz-form-item>
      <nz-form-control nzHasFeedback nzErrorTip="Feature name is required">
        <input class="form-item-bordered" nz-input [(ngModel)]="deleteTextValue" name="feature_delete_text"
               required="true" [placeholder]="'Delete ' + feature.name"/>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>

<ng-template #tplDeleteModalFooter let-ref="modalRef">
  <button nz-button (click)="handleModalClose(ref)" class="form-button">Cancel</button>
  <button nz-button (click)="deleteFeature(ref)" class="form-button" nzType="primary" nzDanger
          [disabled]="deleteTextValue.toLowerCase() != deleteText.toLowerCase()">Delete
  </button>
</ng-template>

<!--</div>-->
