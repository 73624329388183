<nz-input-group [nzSize]="'large'" [nzSuffix]="suffixIconSearch">
  <input
    type="text"
    nz-input
    placeholder="{{placeholder}}"
    [(ngModel)]="searchInputValue"
    [ngModelOptions]="{standalone: true}"
    (ngModelChange)="setSearchValue()"
  />
</nz-input-group>
<ng-template #suffixIconSearch>
  <span nz-icon nzType="search"></span>
</ng-template>
